export default {
    'taskSign': {
        url: '/town/school/user/task/sign/{user_code}',
        serve: 'social'
    },
    'taskList': {
        url: '/town/school/user/task/list/{user_code}',
        serve: 'social'
    },
    'stoneRecord': {
        url: '/town/school/user/task/record/{user_code}',
        serve: 'social'
    },
    'middleTaskList': {
        url: '/town/school/user/task/middle/list/{user_code}',
        serve: 'social'
    },
}