import commonApi from '../_EXT_API'

export default class game extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'taskcenter'
        let name = 'taskcenter'
        opt = {localKey, name}
        super(opt)
    }
    async taskSign(opt){
        opt = opt || {}
        let {user_code,task_id} = opt 
        // schoolId = schoolId || this.PARAMS.school_id
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code,
            task_id,
        }
        let options = {
            method: "post",
            ...opt,
            data:{
                // schoolId
            }
        }
        return await this.requestAction('taskSign', options)
	}
    async taskList(opt){
        opt = opt || {}
        let {user_code} = opt 
        // schoolId = schoolId || this.PARAMS.school_id
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code,
        }
        let options = {
            method: "get",
            ...opt,
            data:{
                // schoolId
            }
        }
        return await this.requestAction('taskList', options)
	}
    async stoneRecord(opt){
        opt = opt || {}
        let {user_code,page, size} = opt
        // school_id = school_id || this.PARAMS.school_id
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code,
        }
        let options = {
            method: "get",
            ...opt,
            data:{
                // school_id,
                page,
                size,
                type:0,
            }
        }
        return await this.requestAction('stoneRecord', options)
    }
    async middleTaskList(opt){
        opt = opt || {}
        let {user_code} = opt 
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt = {
            ...opt,
            user_code,
        }
        let options = {
            method: "get",
            ...opt,
            data:{
                ...opt
            }
        }
        return await this.requestAction('middleTaskList', options)
	}
}
