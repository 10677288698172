export default {
    'purchased': {
        'confirm purchased': '活动期间仅限兑换1个周边商品，是否确认购买{goodsName}物品，当前物品消耗{price}思维币',
        'confirm purchased tip': '购买成功后可主动告知二讲老师，物品将快递到你提供的地址，具体时间以二讲老师告知为准哦！',
        'purchased success': '购买成功',
        'stock double check': '周边商城实物礼品稀缺，请确定是否兑换，活动期间仅支持一个孩子一件商品，一经兑换概不退换',
        'stock purchased': '脑王商城实物礼品稀缺，请确定是否兑换，活动期间仅支持一个孩子一件商品，一经兑换概不退换',
        'stock double purchased': '每次活动期间仅限兑换一次，你已经兑换过{goodsName}了，下次活动再来选择喜欢的商品吧！',

        'stock not enough': '当前商品已经都被小朋友选走了，快去兑换其他商品吧！',
        'stock activity close': '兑换活动将在8月1日开启<br>快咨询二讲老师获取兑换码吧！',
        'stock activity expiration': '活动开放时间为8月1号10:00-9月10日24:00，时效已过期，等下次活动开始再来兑换吧！',
        'redeem code error': '输入有误，如有疑问请联系二讲老师哦～',
        'exchange success': '兑换成功',
        'cdk tips': '邀请身边好友跟着脑王开动大脑，一起训练思维即可获得，详情可以咨询二讲老师哦~',
        'cdk empty': '请输入兑换码！',
    }
}